import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Badge } from '@/components/ui/badge'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

interface MethodologyModalProps {
  trigger: React.ReactNode
}

export const MethodologyModal: React.FC<MethodologyModalProps> = ({
  trigger,
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-2xl max-h-full overflow-scroll">
        <DialogHeader>
          <DialogTitle>Zakat Calculation Methodology</DialogTitle>
        </DialogHeader>
        <h4 tabIndex={1}>
          <strong>Active holdings</strong>
        </h4>
        <div className="flex flex-row justify-center items-center space-x-2 py-4">
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger>
                <Badge variant="outline" className="text-sm">
                  Base value{' '}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  The cash value of a holding according to current market price.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <p className="text-xs md:text-base ">ⅹ</p>
          <p className="text-xs md:text-base ">2.5%</p>
          <p className="text-xs md:text-base ">=</p>
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger>
                <Badge variant="outline" className="text-sm">
                  Zakat amount
                </Badge>
              </TooltipTrigger>
              <TooltipContent side="top">
                <p>
                  the amount of zakat due for a holding in which you are an
                  investor.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <p>
          Active investments are ones that you trade and adjust frequently.
          Holding you intend to keep for less than a year would fall under this
          category. You should treat such holdings the same as cash and pay 2.5%
          of their value as Zakat.
        </p>

        <h5>
          <strong>Passive holdings</strong>
        </h5>
        <div className="flex flex-row justify-center items-center space-x-2 py-4">
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger>
                <Badge variant="outline" className="text-xs md:text-sm">
                  Current assets
                </Badge>
              </TooltipTrigger>
              <TooltipContent align="end" alignOffset={8}>
                Current assets are the cash, inventories, receivables, and other<br/>
                similarly liquid assets of a company that can be converted to<br/>
                cash within a fiscal year.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <p className="text-xs md:text-base ">ⅹ</p>
          <div className="flex flex-col justify-center items-center space-y-2">
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger>
                  <Badge variant="outline" className="text-xs md:text-sm">
                    Shares owned
                  </Badge>
                </TooltipTrigger>
                <TooltipContent sideOffset={12} side="top">
                  This is the number of shares of a given stock that you own.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <div className="w-full border-b border-foreground" />
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger>
                  <Badge variant="outline" className="text-xs md:text-sm">
                    Shares outstanding
                  </Badge>
                </TooltipTrigger>
                <TooltipContent sideOffset={-4} side="bottom">
                  The total number of shares that exist for a business or fund.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <p className="text-xs md:text-base ">ⅹ</p>
          <p className="text-xs md:text-base">2.5%</p>
          <p className="text-xs md:text-base">=</p>
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger>
                <Badge variant="outline" className="text-xs md:text-sm">
                  Zakat amount
                </Badge>
              </TooltipTrigger>
              <TooltipContent side="top" align="start" alignOffset={-8}>
                the amount of zakat due for a holding in which you are an
                investor.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <p>
          Passive holdings are investments that you do not actively trade and
          intend to hold for more than a year. For these, you calculate percent
          ownership of your shares and multiply that by the current assets of
          the business to determine the Zakat liable amount. ETFs and mutual
          funds require recursive application of this calculation to their
          holdings with a weighted aggregation at the end. Scholars have
          approved the use of taking 30% of the current market value of a stock
          or fund as an approximation for current assets when the information is
          not readily available.
        </p>
      </DialogContent>
    </Dialog>
  )
}
