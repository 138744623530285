import { DataTable } from '@/components/ui/data-table'
import { ZakatCalculation } from '@/zakat/types'
import { ColumnDef } from '@tanstack/react-table'

const columnsDef: ColumnDef<ZakatCalculation['holdings'][number]>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <div className="w-[300px]">Holding</div>
    ),
    cell: ({ row }) => {
      return (
        <div className="w-[300px]">
          <h4 className="font-semibold">{row.original.symbol}</h4>
          <p className="font-normal text-xs">{row.original.name}</p>
        </div>
      )
    },
  },
  {
    accessorKey: 'value',
    header: ({ column }) => (
      <div className="w-[200px] text-right">Value</div>
    ),
    cell: ({ row }) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: row.original.currency,
      }).format(row.original.unitPrice * row.original.shares)
      return <div className="text-right w-[200px]">{formatted}</div>
    },
  },
  {
    accessorKey: 'zakatLiableAmount',
    header: ({ column }) => (
      <div className="w-[200px] text-right">Zakat Liable Amount</div>
    ),
    cell: ({ row }) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: row.original.currency,
      }).format(row.original.zakatLiableAmount)
      return <div className="text-right w-[200px]">{formatted}</div>
    },
  },
  {
    accessorKey: 'zakatDue',
    header: ({ column }) => (
      <div className="w-[150px] text-right">Zakat Due</div>
    ),
    cell: ({ row }) => {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: row.original.currency,
      }).format(row.original.zakatDue)
      return <div className="text-right w-[150px]">{formatted}</div>
    },
  },
]

export const ResultsHoldingsTable: React.FC<{
  holdings: ZakatCalculation['holdings']
}> = ({ holdings }) => {
  return (
    <div className="overflow-x-auto">
      <DataTable columns={columnsDef} data={holdings} />
    </div>
  )
}
