import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'
import { Skeleton } from '@/components/ui/skeleton'
import { ZakatHolding } from '@/zakat/types'
import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons'
import { ColumnDef } from '@tanstack/react-table'
import { useMediaQuery } from "@mantine/hooks"
import { Card, CardContent } from '@/components/ui/card'
import { AddHoldingModal } from './add-holding-modal'

export const HoldingsTable: React.FC<{
  holdings: Partial<ZakatHolding>[]
  emptyContent?: React.ReactNode
  removeHolding: (symbol?: string, strategy?: string) => void
  addHolding?: (holding: Partial<ZakatHolding>) => void
  calculate?: () => void
  calculation?: { loading: boolean }
}> = ({ holdings, emptyContent, removeHolding, addHolding, calculate, calculation = { loading: false } }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  
  const columnsDef: ColumnDef<Partial<ZakatHolding>>[] = [
    {
      accessorKey: 'name',
      header: 'Holding',
      cell: ({ row }) => {
        return (
          <div>
            <h4 className="font-semibold">{row.original.symbol}</h4>
            {row.original.dataFetch?.error ? (
              <p className="font-normal text-xs text-destructive">
                This Symbol is currently not supported
              </p>
            ) : (
              <p className="font-normal text-xs">{row.original.name}</p>
            )}
          </div>
        )
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => {
        return (
          <p className="text-sm ">
            {row.original.strategy === 'ACTIVE' ? 'Short-term' : 'Long-term'}
          </p>
        )
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Shares',
    },
    {
      accessorKey: 'unitPrice',
      header: 'Current Price',
      cell: ({ row }) => {
        if (!row.original.dataFetch?.finished) {
          return <Skeleton className="w-[65px] h-[20px] rounded-lg" />
        }
        if (row.original.dataFetch?.error) {
          return <p>–</p>
        }
        if (!row.original.unitPrice) {
          return null
        }
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: row.original.currency,
        }).format(row.original.unitPrice)
        return <p>{formatted}</p>
      },
    },
    {
      header: 'Value',
      cell: ({ row }) => {
        if (!row.original.dataFetch?.finished) {
          return <Skeleton className="w-[75px] h-[20px] rounded-lg" />
        }
        if (row.original.dataFetch?.error) {
          return <p>–</p>
        }
        if (!row.original.unitPrice || !row.original.quantity) {
          return null
        }
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: row.original.currency,
        }).format(row.original.unitPrice * row.original.quantity)
        return <p>{formatted}</p>
      },
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <Button
          variant="ghost"
          onClick={(e) =>
            removeHolding(row.original.symbol, row.original.strategy)
          }
        >
          <Cross2Icon height={20} width={20} color="black" />
        </Button>
      ),
    },
  ]

  if (!isDesktop) {
    if (holdings.length === 0) {
      return <>{emptyContent}</>
    }

    return (
      <div className="space-y-10">
        {holdings.map((holding) => (
          <Card key={`${holding.symbol}-${holding.strategy}`}>
            <CardContent className="!p-0">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-semibold">{holding.symbol}</h4>
                  {holding.dataFetch?.error ? (
                    <p className="font-normal text-xs text-destructive">
                      This Symbol is currently not supported
                    </p>
                  ) : (
                    <p className="font-normal text-xs text-muted-foreground">{holding.name}</p>
                  )}
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeHolding(holding.symbol, holding.strategy)}
                >
                  <Cross2Icon height={16} width={16} />
                </Button>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <p className="text-sm text-muted-foreground">Type</p>
                  <p className="text-sm font-medium">
                    {holding.strategy === 'ACTIVE' ? 'Short-term' : 'Long-term'}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Shares</p>
                  <p className="text-sm font-medium">{holding.quantity}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Current Price</p>
                  {!holding.dataFetch?.finished ? (
                    <Skeleton className="w-[65px] h-[20px] rounded-lg" />
                  ) : holding.dataFetch?.error ? (
                    <p className="text-sm font-medium">–</p>
                  ) : (
                    <p className="text-sm font-medium">
                      {holding.unitPrice && new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: holding.currency,
                      }).format(holding.unitPrice)}
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Value</p>
                  {!holding.dataFetch?.finished ? (
                    <Skeleton className="w-[75px] h-[20px] rounded-lg" />
                  ) : holding.dataFetch?.error ? (
                    <p className="text-sm font-medium">–</p>
                  ) : (
                    <p className="text-sm font-medium">
                      {holding.unitPrice && holding.quantity && new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: holding.currency,
                      }).format(holding.unitPrice * holding.quantity)}
                    </p>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <DataTable
        columns={columnsDef}
        data={holdings}
        emptyContent={emptyContent}
      />
    </div>
  )
}
