import { UserDropdown } from '@/common/components/user-dropdown'
import { ZoyaLogo } from '@/common/components/zoya-logo'
import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import {
  HamburgerMenuIcon,
  InfoCircledIcon,
  PlusIcon,
  CaretSortIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons'
import { ColumnDef } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import {
  ZakatCalculation,
  ZakatCalculationResponse,
  ZakatHolding,
} from '../types'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { AddHoldingModal } from './components/add-holding-modal'
import { MethodologyModal } from './components/methodology-modal'
import { useZakatCalculator } from '../hooks/use-zakat-calculator'
import { useCurrentUser } from '@/auth/hooks/use-current-user'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { useMemo } from 'react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { ResultsHoldingsTable } from './components/result-holdings-table'
import { HoldingsTable } from './components/holdings-table'
import { useScrollIntoView } from '@mantine/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faFacebookSquare,
  faSquareXTwitter,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons'

const navItems = [
  {
    label: 'Community',
    href: 'https://community.zoya.finance',
  },
  {
    label: 'Blog',
    href: 'https://blog.zoya.finance',
  },
  {
    label: 'About',
    href: 'https://zoya.finance/about',
  },
  {
    label: 'API',
    href: 'https://zoya.finance/api',
  },
]

export const CalculatorPage = () => {
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
    duration: 500,
  })
  const { holdings, calculation, addHolding, removeHolding, calculate } =
    useZakatCalculator({
      onCalculationSuccess: scrollIntoView,
    })

  const resultActiveHoldings = useMemo(() => {
    if (!calculation.result) {
      return null
    }
    return calculation.result?.holdings.filter((_) => _.strategy === 'ACTIVE')
  }, [calculation.result])
  const resultPassiveHoldings = useMemo(() => {
    if (!calculation.result) {
      return null
    }
    return calculation.result?.holdings.filter((_) => _.strategy === 'PASSIVE')
  }, [calculation.result])

  return (
    <div className="relative min-h-screen space-y-6 xs:p-0  sm:p-2 lg:p-10 lg:max-w-screen-xl bg-background-80 flex flex-col content-center mx-auto">
      <header className="sticky container flex items-center justify-between space-y-2 py-4 sm:flex-row sm:items-center sm:space-y-0 md:h-16">
        <Link to="/">
          <ZoyaLogo className="w-28 max-w-28 align-middle" />
        </Link>
        <nav className="hidden md:flex ml-0 space-x-12 sm:justify-end items-center">
          <Button variant="default">
            <a href="http://onelink.to/zoya" target="_blank">
              Get the app
            </a>
          </Button>
        </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <HamburgerMenuIcon className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <Link
                to="/"
                className="flex items-center gap-2 text-lg font-semibold"
              >
                <ZoyaLogo className="w-28" />
              </Link>
              {navItems.map((_) => (
                <a
                  key={_.label}
                  href={_.href}
                  className="flex items-center gap-2 text-lg font-normal"
                  target="_blank"
                >
                  {_.label}
                </a>
              ))}
              <Button variant="default">Get the app</Button>
            </nav>
          </SheetContent>
        </Sheet>
      </header>
      <div className="flex flex-col space-y-8 md:flex-row md:justify-center lg:space-x-12 lg:space-y-0">
        <div className="flex-1  px-6">
          <div className="grid grid-cols-1 gap-8 py-12">
            <div className="text-center ">
              <h1 className="text-xl font-bold md:text-2xl">
                Calculate Zakat on Stocks
              </h1>
              <p className="text-sm md:text-xl">
                <span className="avoidwrap">
                  A free tool to help you instantly and accurately calculate{' '}
                </span>
                <br className="hidden md:block" />
                <span className="avoidwrap">
                  how much zakat you owe on your investments.
                </span>
              </p>
            </div>
            <div className="flex justify-center">
              <MethodologyModal
                trigger={
                  <Button variant="secondary">
                    <InfoCircledIcon className="mr-1" />
                    Methodology
                  </Button>
                }
              />
            </div>
            <Card>
              <CardHeader>
                {calculation.error && (
                  <Alert variant="destructive" className="mb-4">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <AlertTitle>Calculation failure</AlertTitle>
                    <AlertDescription>
                      {calculation.error?.message}
                    </AlertDescription>
                  </Alert>
                )}
                <div className="flex flex-col sm:flex-row justify-between">
                  <h1 className="text-xl font-semibold pb-2">
                    Holdings
                    <span className="font-normal">
                      {' '}
                      {holdings.length ? `(${holdings.length})` : ''}
                    </span>
                  </h1>
                  <div className="hidden md:flex flex-row flex-wrap flex-1 items-center sm:justify-end justify-start">
                    <AddHoldingModal
                      trigger={
                        <Button variant="outline" size="sm" className="mr-2 mb-2">
                          <PlusIcon className="mr-2 h-4 w-4" />
                          Add Holding
                        </Button>
                      }
                      addHolding={addHolding}
                    />
                    {holdings.length !== 0 && (
                      <Button
                        variant="default"
                        size="sm"
                        className="mr-2 mb-2"
                        onClick={calculate}
                        loading={calculation.loading}
                        disabled={calculation.loading}
                      >
                        Calculate
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <HoldingsTable
                  holdings={holdings}
                  emptyContent={
                    <div className="h-[360px] flex flex-col justify-center items-center">
                      <AddHoldingModal
                        trigger={
                          <Button variant="outline" size="sm">
                            <PlusIcon className="mr-2 h-4 w-4" />
                            Add Holding
                          </Button>
                        }
                        addHolding={addHolding}
                      />
                    </div>
                  }
                  removeHolding={removeHolding}
                  addHolding={addHolding}
                  calculate={calculate}
                />
              </CardContent>
            </Card>
            <div ref={targetRef} className="scroll-mt-16">
              {calculation.result?.zakatDue && (
                <Card className="md:bg-transparent md:border-[3px] md:border-dashed md:border-gray-300 md:[border-style:dashed] md:[border-dash:16px] md:[border-spacing:8px] bg-background border-0">
                  <CardContent className="p-0 md:!p-6">
                    <div className="space-y-6 py-10">
                      <div className="flex flex-col justify-center items-center">
                        <h1 className="text-6xl font-semibold">
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(calculation.result.zakatDue)}
                        </h1>
                        <h2 className="text-lg text-muted-foreground mt-2">Total zakat due</h2>
                      </div>
                      
                      <div className="flex justify-center">
                        <div className="border rounded-lg p-6 inline-block max-w-[428px]">
                          <div className="text-center">
                            <h2 className="text-xl font-semibold text-gray-900">Never Miss Laylatul Qadr</h2>
                            <p className="mt-2 text-sm text-muted-foreground">
                              Automate your donations over the last 10 nights of Ramadan. "The night of Glory is better than a thousand months." (Quran 97:3)
                            </p>
                          </div>
                          <div className="flex justify-center pt-4">
                            <a href="https://mytennights.com/partner/zoya" target="_blank" rel="noopener noreferrer">
                              <Button variant="default" size="lg" className="font-medium">
                                Donate Your Zakat Now
                              </Button>
                            </a>
                          </div>
                          <div className="text-center mt-2">
                            <p className="text-xs text-muted-foreground font-medium">
                              100% donation policy
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="pt-6">
                        <Collapsible defaultOpen>
                          <CollapsibleTrigger className="flex flex-row w-full justify-center items-center gap-1">
                            <p className="text-sm ">Show breakdown</p>
                            <CaretSortIcon width={18} height={18} />
                          </CollapsibleTrigger>
                          <CollapsibleContent className="py-4">
                            {resultActiveHoldings &&
                              resultActiveHoldings.length > 0 && (
                                <div className="mb-8 pb-4">
                                  <p className="text-lg py-2 mb-2 font-medium p-2 rounded-md">
                                    Short-term holdings
                                  </p>
                                  <div className="md:block hidden">
                                    <ResultsHoldingsTable
                                      holdings={resultActiveHoldings}
                                    />
                                  </div>
                                  <div className="md:hidden space-y-3">
                                    {resultActiveHoldings.map((holding, index) => (
                                      <Card key={index} className="border-l-4 border-l-blue-400">
                                        <CardContent className="!p-3">
                                          <div className="flex justify-between items-start">
                                            <div>
                                              <h4 className="font-semibold">{holding.symbol}</h4>
                                              <p className="font-normal text-xs text-muted-foreground">{holding.name}</p>
                                            </div>
                                          </div>

                                          <div className="grid grid-cols-2 gap-3 mt-4">
                                            <div>
                                              <p className="text-sm text-muted-foreground">Market Value</p>
                                              <p className="text-sm font-medium">
                                                {new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: holding.currency,
                                                }).format(holding.unitPrice * holding.shares)}
                                              </p>
                                            </div>
                                            <div>
                                              <p className="text-sm text-muted-foreground">Zakat Liable Amount</p>
                                              <p className="text-sm font-medium">
                                                {new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: holding.currency,
                                                }).format(holding.zakatLiableAmount)}
                                              </p>
                                            </div>
                                            <div>
                                              <p className="text-sm text-muted-foreground">Zakat Due</p>
                                              <p className="text-sm font-medium">
                                                {new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: holding.currency,
                                                }).format(holding.zakatDue)}
                                              </p>
                                            </div>
                                          </div>
                                        </CardContent>
                                      </Card>
                                    ))}
                                  </div>
                                </div>
                              )}
                            {resultPassiveHoldings &&
                              resultPassiveHoldings.length > 0 && (
                                <div>
                                  <p className="text-lg py-2 mb-2 font-medium p-2 rounded-md">
                                    Long-term holdings
                                  </p>
                                  <div className="md:block hidden">
                                    <ResultsHoldingsTable
                                      holdings={resultPassiveHoldings}
                                    />
                                  </div>
                                  <div className="md:hidden space-y-3">
                                    {resultPassiveHoldings.map((holding, index) => (
                                      <Card key={index} className="border-l-4 border-l-green-400">
                                        <CardContent className="!p-3">
                                          <div className="flex justify-between items-start">
                                            <div>
                                              <h4 className="font-semibold">{holding.symbol}</h4>
                                              <p className="font-normal text-xs text-muted-foreground">{holding.name}</p>
                                            </div>
                                          </div>

                                          <div className="grid grid-cols-2 gap-3 mt-4">
                                            <div>
                                              <p className="text-sm text-muted-foreground">Market Value</p>
                                              <p className="text-sm font-medium">
                                                {new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: holding.currency,
                                                }).format(holding.unitPrice * holding.shares)}
                                              </p>
                                            </div>
                                            <div>
                                              <p className="text-sm text-muted-foreground">Zakat Liable Amount</p>
                                              <p className="text-sm font-medium">
                                                {new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: holding.currency,
                                                }).format(holding.zakatLiableAmount)}
                                              </p>
                                            </div>
                                            <div>
                                              <p className="text-sm text-muted-foreground">Zakat Due</p>
                                              <p className="text-sm font-medium">
                                                {new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: holding.currency,
                                                }).format(holding.zakatDue)}
                                              </p>
                                            </div>
                                          </div>
                                        </CardContent>
                                      </Card>
                                    ))}
                                  </div>
                                </div>
                              )}
                          </CollapsibleContent>
                        </Collapsible>
                      </div>
                      
                      <div className="text-center pt-4">
                        <p className="text-md font-semibold ">
                          Share this calculator with your friends
                        </p>
                        <div className="flex flex-row justify-center items-center gap-2 pt-2">
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://api.whatsapp.com/send?text=Check%20out%20this%20free%20tool%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately.%20https%3A%2F%2Fzoya.finance%2Fzakat"
                          >
                            <FontAwesomeIcon
                              icon={faWhatsappSquare}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fzoya.finance%2Fzakat&quote=Check%20out%20this%20free%20tool%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately."
                          >
                            <FontAwesomeIcon
                              icon={faFacebookSquare}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fzoya.finance/zakat"
                          >
                            <FontAwesomeIcon
                              icon={faLinkedin}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener"
                            href="https://twitter.com/intent/tweet?source=https%3A%2F%2Fapp.zoya.finance%2Fzakat&text=Check%20out%20this%20free%20tool%20by%20%40zoyafinance%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately.&url=https%3A%2F%2Fzoya.finance%2Fzakat"
                          >
                            <FontAwesomeIcon
                              icon={faSquareXTwitter}
                              className="w-8 h-8 hover:opacity-60"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
      {holdings.length > 0 && (
        <div className="md:hidden fixed bottom-0 left-0 right-0 p-4 bg-background border-t flex gap-2 justify-end">
          <AddHoldingModal
            trigger={
              <Button variant="outline" size="sm">
                <PlusIcon className="mr-2 h-4 w-4" />
                Add Holding
              </Button>
            }
            addHolding={addHolding}
          />
          <Button
            variant="default"
            size="sm"
            onClick={calculate}
            loading={calculation.loading}
            disabled={calculation.loading}
          >
            Calculate
          </Button>
        </div>
      )}
    </div>
  )
}
