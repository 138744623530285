import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useTypesenseSearch } from '@/data/hooks/useTypesenseSearch'
import { ZakatHolding } from '@/zakat/types'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useConfig } from 'statsig-react'
import { z } from 'zod'
import { isString, isEmpty, noop } from 'lodash'
import { SearchResponseHit } from 'typesense/lib/Typesense/Documents'
import { InputWithOptions } from '@/common/components/searchable-select'
import { useMediaQuery } from '@mantine/hooks'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { useToast } from '@/hooks/use-toast'

const TYPESENSE_HOST = import.meta.env.VITE_TYPESENSE_HOST
const TYPESENSE_KEY = import.meta.env.VITE_TYPESENSE_KEY
const TYPESENSE_COLLECTION = import.meta.env.VITE_TYPESENSE_COLLECTION

export type ZakatSearchResult = {
  symbol: string
  name: string
  currency: string
  status: string
  region: string
  issueType: string
}

export const isNonEmptyString = (value: unknown): value is string => {
  return isString(value) && !isEmpty(value)
}

interface AddHoldingModalProps {
  trigger: React.ReactNode
  addHolding: (newHolding: Partial<ZakatHolding>) => void
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const AddHoldingSchema = z.object({
  symbol: z.string().min(1, "Symbol can't be empty"),
  name: z.string().optional(),
  quantity: z.coerce.number().gt(0),
  strategy: z.union([z.literal('PASSIVE'), z.literal('ACTIVE')]),
  currency: z.string().optional(),
  region: z.string().optional(),
  issueType: z.string().optional(),
  keepOpen: z.boolean().default(false),
})

const zakatSearchQueryBy = ['symbol', 'name'] as const

export const AddHoldingModal: React.FC<AddHoldingModalProps> = (props) => {
  const { trigger, addHolding } = props
  const isControlled =
    typeof props.open !== 'undefined' &&
    typeof props.onOpenChange === 'function'
  const [openInternal, setOpenInternal] = useState(false)
  const openState = isControlled ? props.open : openInternal
  const setOpenState = isControlled ? props.onOpenChange! : setOpenInternal

  const { toast } = useToast()
  const isDesktop = useMediaQuery('(min-width: 768px)')

  const form = useForm<z.infer<typeof AddHoldingSchema>>({
    defaultValues: {
      symbol: '',
      name: '',
      quantity: 0,
      strategy: 'PASSIVE',
      keepOpen: false,
    },
    resolver: zodResolver(AddHoldingSchema),
    mode: 'onBlur',
  })

  const symbolValue = form.watch('symbol')

  const { config } = useConfig('search_config')

  const typesenseOptions = useMemo(() => {
    return {
      host: config.get('host', TYPESENSE_HOST, isNonEmptyString),
      apiKey: config.get(
        'zakatOverrideApiKey',
        config.get('apiKey', TYPESENSE_KEY, isNonEmptyString),
        isNonEmptyString,
      ),
      collection: config.get(
        'zakatOverrideCollection',
        config.get('collection', TYPESENSE_COLLECTION, isNonEmptyString),
        isNonEmptyString,
      ),
    }
  }, [config])

  const [symbolSearchResults, setSymbolSearchResults] = useState<
    {
      title: string
      description: string
      value: ZakatSearchResult
    }[]
  >([])

  const onSearchSuccess = useCallback((newResults: ZakatSearchResult[]) => {
    const next = newResults.map((_) => ({
      value: _,
      title: _.symbol,
      description: _.name,
    }))
    setSymbolSearchResults(next)
  }, [])

  const parseResults = useCallback(
    (result: SearchResponseHit<ZakatSearchResult>[]) => {
      return result.map((_) => _.document)
    },
    [],
  )

  useTypesenseSearch<ZakatSearchResult>({
    query: symbolValue,
    pageSize: 20, // The API key we use currently forces this to be 20
    debounceWait: 250,
    queryBy: zakatSearchQueryBy,
    sortBy: '_text_match:desc,tradeable:desc',
    options: typesenseOptions,
    onSearchSuccess,
    onSearchError: noop,
    parseResults,
  })

  const onSubmit = async (data: z.infer<typeof AddHoldingSchema>) => {
    try {
      await addHolding(data)

      // Reset the form fields and preserve the keepOpen state from submission.
      form.reset({
        symbol: '',
        name: '',
        quantity: 0,
        strategy: 'PASSIVE',
        currency: '',
        region: '',
        issueType: '',
        keepOpen: data.keepOpen, // Preserve current keepOpen value
      })

      // Explicitly set the drawer state to match the keepOpen value.
      // (If data.keepOpen is true the drawer is kept open; if false it will close.)
      setOpenState(data.keepOpen)

      if (!isDesktop) {
        toast({
          title: 'Holding Added',
          description: `Successfully added ${data.quantity} shares of ${data.symbol}`,
          duration: 3000,
        })
      }
    } catch (error) {
      if (!isDesktop) {
        toast({
          title: 'Error',
          description: 'Failed to add holding. Please try again.',
          variant: 'destructive',
        })
      }
    }
  }

  const formContent = (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <div className="flex-1 flex-col items-center space-y-4 px-6 sm:px-0">
          <FormField
            control={form.control}
            name="symbol"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Symbol</FormLabel>
                <FormControl>
                  <InputWithOptions
                    inputProps={field}
                    options={symbolSearchResults}
                    onOptionSelected={(option) => {
                      form.setValue('symbol', option.value.symbol)
                      form.setValue('name', option.value.name)
                      form.setValue('issueType', option.value.issueType)
                      form.setValue('currency', option.value.currency)
                      form.setValue('region', option.value.region)
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="quantity"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Shares</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    inputMode="decimal"
                    step="any"
                    pattern="[0-9]*[.,]?[0-9]*"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Enter the number of shares you hold
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="strategy"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Type</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue defaultValue="PASSIVE" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="PASSIVE">
                        Long-term (Passive)
                      </SelectItem>
                      <SelectItem value="ACTIVE">
                        Short-term (Active)
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Select whether your intention is to hold them long-term <br />
                  (&gt; 12 months) or short-term (&lt; 12 months).
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="keepOpen"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                <FormControl>
                  <input
                    type="checkbox"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300"
                  />
                </FormControl>
                <FormLabel className="font-normal">
                  Keep form open to add another
                </FormLabel>
              </FormItem>
            )}
          />
        </div>

        {isDesktop ? (
          <DialogFooter className="sm:justify-start sm:mt-6">
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Close
              </Button>
            </DialogClose>
            <Button type="submit" formAction="submit" variant="default">
              Add
            </Button>
          </DialogFooter>
        ) : (
          <DrawerFooter>
            <Button type="submit" formAction="submit" variant="default">
              Add
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setOpenState(false)}
            >
              Close
            </Button>
          </DrawerFooter>
        )}
      </form>
    </Form>
  )

  if (isDesktop) {
    return (
      <Dialog open={openState} onOpenChange={setOpenState}>
        <DialogTrigger asChild>{trigger}</DialogTrigger>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Add a Holding</DialogTitle>
            <DialogDescription>
              Add a stock or ETF to calculate zakat on
            </DialogDescription>
          </DialogHeader>
          {formContent}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={openState} onOpenChange={setOpenState}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Add a Holding</DrawerTitle>
        </DrawerHeader>
        {formContent}
      </DrawerContent>
    </Drawer>
  )
}
